import { Alert } from "@mui/material";
import { isNull } from "lodash";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";
import buildPath from "src/utils/buildPath";
import fetchUser from "src/utils/fetchUser";
import { setSession } from "src/utils/jwt";
import Ternary from "./ternary";

const ImpersonationBanner = () => {
    const { translate } = useLocales();
    const { user } = useAuth();
    const isImpersonate = localStorage.getItem("isImpersonate");
    const goBackToAdmin = async () => {
        const params = {
            sub_admin_impersonate: localStorage.getItem("source_id") || null,
        };
        try {
            const { status, data } = await fetchUser("back-to-admin", {
                params,
            });
            const { access_token, menu_list, user: impersonateUser } = data;
            if (status === 200) {
                localStorage.setItem(
                    "isAdmin",
                    Boolean(impersonateUser.is_super_admin)
                );
                localStorage.setItem("menu", JSON.stringify(menu_list));
                localStorage.setItem(
                    "isSubAdmin",
                    Boolean(impersonateUser.is_sub_admin)
                );
                localStorage.removeItem("isImpersonate");
                setSession(access_token);

                const impersonationSource = localStorage.getItem(
                    "impersonationSource"
                );
                if (impersonationSource) {
                    window.location = `${window.origin}${impersonationSource}`;
                    return;
                }
                window.location = window.origin;
            }
        } catch (err) {
            console.error(err);
        }
    };
    return (
        <Ternary
            when={isImpersonate}
            then={
                <Alert severity='primary' sx={{ marginBottom: 1 }}>
                    {translate("impoersonation.heads")} {user.username}&nbsp;
                    <strong
                        onClick={goBackToAdmin}
                        style={{
                            textDecoration: "none",
                            cursor: "pointer",
                        }}>
                        {translate("impoersonation.click")}
                    </strong>
                    ,{translate("impoersonation.toGo")}
                </Alert>
            }
        />
    );
};

export default ImpersonationBanner;
