import React from "react";

const checkLogin = () => {
    const token = localStorage.getItem("accessToken");
    const isSubAdmin = JSON.parse(localStorage.getItem("isSubAdmin"));
    const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
    const menu_lists = JSON.parse(localStorage.getItem("menu") || "[]");
    if (token) {
        if (isAdmin) {
            return (window.location = "/admin");
        }
        if (isSubAdmin) {
            const [menu] = menu_lists;
            const { path, children } = menu?.items[0] || {};

            if (path.includes("dashboard") || path.includes("/admin/report")) {
                return children[0]?.path;
            }

            return path;
        }
    }
    localStorage.removeItem("menu");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("isSubAdmin");
    localStorage.removeItem("isImpersonate");
    localStorage.removeItem("u_name");
    localStorage.removeItem("source_id");
    localStorage.removeItem("accessToken");
    return "/auth/login";
};

export default checkLogin;
