import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import useGetTokenList from "src/pages/whisper-drop/components/all-wallet-address-table/hooks/use-get-token-list";
import { ethers } from "ethers";
import Loop from "src/components/loop";
import axiosInstance from "src/utils/axios";
import { LoadingButton } from "@mui/lab";
import WhisperDropLinkItem from "src/components/whisper-drop-link-item";
import { NETWORK } from "src/config";
import getChain from "src/pages/whisper-drop/components/multi-sender/multi-transfer/utils/get-chain.js";
import getSigner from "src/pages/whisper-drop/components/multi-sender/multi-transfer/utils/get-signer.js";
import Ternary from "src/components/ternary";
const abi = [
    {
        name: "transfer",
        type: "function",
        inputs: [
            {
                name: "_to",
                type: "address",
            },
            {
                type: "uint256",
                name: "_tokens",
            },
        ],
        constant: false,
        outputs: [],
        payable: false,
    },
];
const getTokenContract = async (token) => {
    const chain = getChain()[NETWORK];
    const signer = await getSigner(chain);

    const tokenContract = new ethers.Contract(token, abi, signer);

    return tokenContract;
};
const TokenTransfer = ({ userId, walletAddress, handleClose }) => {
    const list = useGetTokenList(true);
    const [tokenAmount, setTokenAmount] = useState("");
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [selectedToken, setSelectedToken] = useState("");
    const [errors, setErrors] = useState({ amount: "" });
    const send = async (address, amount) => {
        if (!Boolean(amount)) {
            setErrors((prev) => {
                return { ...prev, amount: "Token is required" };
            });
            return;
        }
        setLoading(true);
        const contract = await getTokenContract(selectedToken);
        contract
            .transfer(address, ethers.utils.parseUnits(amount, 18).toString())
            .then(async (tx) => {
                const transactionData = {
                    transaction_hash: tx?.hash,
                    token: list?.find(
                        ({ token_contract_address }) =>
                            token_contract_address === selectedToken
                    )?.label,
                    token_contract_address: selectedToken,
                    amount,
                    from_address: tx?.from,
                    to_address: address,
                    user_id: userId,
                };
                await tx.wait();
                const reqData = new FormData();
                Object.entries(transactionData).forEach(([k, v]) =>
                    reqData.append(k, v)
                );

                try {
                    const { data } = await axiosInstance.post(
                        "api/admin/transaction-history",
                        reqData
                    );

                    enqueueSnackbar(data.message);
                    setLoading(false);
                } catch (err) {
                    enqueueSnackbar(err.message, { variant: "error" });
                    setLoading(false);
                }
                setTokenAmount("");
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setTokenAmount("");
                enqueueSnackbar("Failed to send token ", { variant: "error" });
                console.log(err.message);
            });
    };

    useEffect(() => {
        if (list.length > 0) {
            const firstItem = list?.find(Boolean);
            setSelectedToken(firstItem.token_contract_address);
        }
    }, [list]);
    useEffect(() => {
        if (Boolean(tokenAmount)) {
            setErrors((prev) => {
                return { ...prev, amount: "" };
            });
        }
    }, [tokenAmount]);
    return (
        <>
            <DialogContent>
                <Ternary
                    when={Boolean(walletAddress)}
                    then={
                        <Stack sx={{ whiteSpace: "nowrap" }} spacing={1}>
                            <Stack direction='row'>
                                <WhisperDropLinkItem
                                    address={walletAddress}
                                    base='https://bscscan.com/address'
                                    length={30}
                                />
                            </Stack>
                            <TextField
                                fullWidth
                                onChange={(e) =>
                                    setSelectedToken(e.target.value)
                                }
                                value={selectedToken}
                                size='small'
                                select
                                SelectProps={{ native: true }}>
                                <Loop
                                    list={list}
                                    render={({
                                        label,
                                        token_contract_address,
                                    }) => {
                                        return (
                                            <option
                                                value={token_contract_address}>
                                                {label}
                                            </option>
                                        );
                                    }}
                                />
                            </TextField>
                            <TextField
                                fullWidth
                                label='Token To Send'
                                size='small'
                                error={Boolean(errors.amount)}
                                helperText={
                                    Boolean(errors.amount) ? errors.amount : ""
                                }
                                value={tokenAmount}
                                onChange={(e) => setTokenAmount(e.target.value)}
                            />
                        </Stack>
                    }
                    otherwise={
                        <>
                            <Typography
                                color='text.secondary'
                                variant='subtitle2'>
                                No wallet address found for this user.
                            </Typography>
                        </>
                    }
                />
            </DialogContent>

            <DialogActions>
                <Ternary
                    when={Boolean(walletAddress)}
                    then={
                        <LoadingButton
                            size='small'
                            variant='contained'
                            loading={loading}
                            onClick={() => send(walletAddress, tokenAmount)}>
                            send
                        </LoadingButton>
                    }
                />

                <Button
                    onClick={handleClose}
                    variant='outlined'
                    color='warning'>
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
};

export default TokenTransfer;
