import axios from "axios";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import useAuth from "src/hooks/useAuth";
import { PATH_AUTH } from "src/routes/paths";
import axiosInstance from "src/utils/axios";
import { setSession } from "src/utils/jwt";

const GoogleLogin = () => {
    const { getUser } = useAuth();
    const { g_token } = useParams();
    const navigate = useNavigate();
    const handleSuccess = async (email, g_token) => {
        const reqData = new FormData();
        reqData.append("email", email);
        reqData.append("g_token", g_token);
        try {
            const { status, data } = await axiosInstance.post(
                "api/google/login",
                reqData
            );
            if (status === 200) {
                const { access_token, user, menu_lists } = data || {};
                if (Boolean(access_token)) {
                    localStorage.setItem("menu", JSON.stringify(menu_lists));
                    setSession(access_token);
                    localStorage.setItem(
                        "isAdmin",
                        Boolean(user.is_super_admin)
                    );
                    localStorage.setItem(
                        "isSubAdmin",
                        Boolean(user.is_sub_admin)
                    );
                    getUser();

                    window.location = "/";
                } else {
                    navigate(PATH_AUTH.login, {
                        state: data.message,
                        replace: true,
                    });
                }
            }
        } catch (err) {
            console.error(err);
            window.location = "/";
        }
    };

    const getLoggedInUserInfo = async (g_token) => {
        try {
            const { status, data } = await axios(
                `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${g_token}`,
                {
                    headers: {
                        Authorization: `Bearer ${g_token}`,
                        Accept: "application/json",
                    },
                }
            );
            if (status === 200) {
                handleSuccess(data.email, g_token);
            }
        } catch (err) {
            console.error(console.error("Retrieving Failed:", err));
        }
    };

    useEffect(() => {
        if (g_token) {
            getLoggedInUserInfo(g_token);
        }
    }, [g_token]);

    return null;
};

export default GoogleLogin;
