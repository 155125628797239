import React from "react";

const reorderByPlacement = (data = []) => {
    return data.map((obj) => {
        if (obj.items) {
            obj.items.sort((a, b) => a.placement - b.placement);
            obj.items.forEach((item) => {
                if (item.children) {
                    item.children.sort((a, b) => a.placement - b.placement);
                }
            });
        }
        return obj;
    });
};

export default reorderByPlacement;
