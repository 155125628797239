import { yupResolver } from "@hookform/resolvers/yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputAdornment,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Iconify from "src/components/Iconify";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import useErrors from "src/hooks/useErrors";
import useLocales from "src/hooks/useLocales";
import axiosInstance from "src/utils/axios";
import * as Yup from "yup";

const validationSchema = Yup.object({
    username: Yup.string().required("User Name is required"),
    password: Yup.string().min(6).required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
});

const defaultValues = {
    username: "",
    password: "",
    confirmPassword: "",
    _method: "PUT",
};

const UpdatePasswordDialog = ({ open, username, onClose, fetchData }) => {
    const { translate } = useLocales();
    const theme = useTheme();
    const { palette } = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { enqueueSnackbar } = useSnackbar();
    const handleErrors = useErrors();
    const methods = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const onSubmit = async (inputData) => {
        const reqData = new FormData();
        delete inputData.confirmPassword;

        [...Object.entries(inputData)].forEach(([key, value]) =>
            reqData.append(key, value)
        );

        try {
            const { status, data } = await axiosInstance.post(
                "api/admin/change-user-password",
                reqData
            );
            if (status === 200) {
                fetchData();
                onClose();
                enqueueSnackbar(data.message);
            }
        } catch (err) {
            handleErrors(err);
        }
    };

    useEffect(() => {
        if (username) methods.setValue("username", username);
    }, [username]);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    return (
        <Dialog
            fullWidth
            maxWidth='sm'
            open={open}
            onClose={onClose}
            aria-labelledby='change-username'>
            <DialogTitle
                id='change-username'
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                <Typography variant='span' sx={{ color: "#444" }}>
                    {translate(
                        "adminMembersManagement.networkMembers.changePassword"
                    )}
                </Typography>
                <IconButton aria-label='close' onClick={onClose}>
                    <Iconify icon='ic:baseline-close' />
                </IconButton>
            </DialogTitle>
            <FormProvider
                methods={methods}
                onSubmit={methods.handleSubmit(onSubmit)}>
                <DialogContent>
                    <DialogContentText>
                        <Box
                            sx={{
                                display: "grid",
                                rowGap: 3,
                                columnGap: 2,
                                gridTemplateColumns: {
                                    xs: "repeat(1, 1fr)",
                                    sm: "repeat(1, 1fr)",
                                },
                            }}>
                            <RHFTextField
                                name='username'
                                label={translate(
                                    "adminMembersManagement.networkMembers.userName"
                                )}
                                disabled
                            />
                            <RHFTextField
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword
                                                    )
                                                }
                                                aria-label='toggle password visibility'
                                                edge='end'>
                                                {showPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                name='password'
                                type={showPassword ? "text" : "password"}
                                label={translate(
                                    "adminMembersManagement.networkMembers.password"
                                )}
                            />
                            <RHFTextField
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={() =>
                                                    setShowChangePassword(
                                                        !showChangePassword
                                                    )
                                                }
                                                aria-label='toggle password visibility'
                                                edge='end'>
                                                {showChangePassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                name='confirmPassword'
                                type={showChangePassword ? "text" : "password"}
                                label={translate(
                                    "adminMembersManagement.networkMembers.confirmPassword"
                                )}
                            />
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        autoFocus
                        sx={{ color: palette.warning.normal }}>
                        {translate(
                            "adminMembersManagement.networkMembers.close"
                        )}
                    </Button>
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        loading={methods.formState.isSubmitting}>
                        {translate(
                            "adminMembersManagement.networkMembers.edit"
                        )}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};

export default UpdatePasswordDialog;
