import React, { useEffect, useState } from "react";
import axiosInstance from "src/utils/axios";

const useGetUserWalletAddress = (id) => {
    const [walletAddress, setWalletAddress] = useState(null);

    const fetchData = async () => {
        try {
            const { data, status } = await axiosInstance(
                `api/admin/user-wallet/${id}`
            );
            if (status) {
                setWalletAddress(data.data.wallet_address);
            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id]);
    return [walletAddress];
};

export default useGetUserWalletAddress;
